import Header from './layout/Header.js'
import Footer from './layout/Footer.js'
import Home from './components/Home.js'
import About from './components/About.js'
import { Routes, Route } from "react-router-dom";
import Contact from './components/Contact.js';
import Courses from './components/Courses.js';
import ImageGallery from './components/ImageGallery.js';
import Results from "./components/Results.js";
import PageNotFound from './components/PageNotFound.js';
import AllTeam from './components/AllTeam.js';

function App() {
  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<ImageGallery />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/results" element={<Results />} />

        <Route path="/allteam" element={<AllTeam />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <Footer />
      <a
        href="https://wa.me/+917415486473"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i
          class="fa-brands fa-whatsapp  whatsapp-icon"
          style={{ color: "#ffffff" }}
        ></i>
      </a>
      <a
        href="tel:+917415486473"
        class="call_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-phone call-icon" style={{ color: "#ffffff" }}></i>
      </a>
    </div>
  );
}

export default App