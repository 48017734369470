// import React from 'react';
// // import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// // import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';

// import { hydrate, render } from "react-dom";

// const APP = (
//   <BrowserRouter>
//      <App />
//   </BrowserRouter>
// );
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(APP , rootElement);
// } else {
//   render(APP , rootElement);
// }

// // const root = ReactDOM.createRoot(document.getElementById('root'));
// // root.render(
// //
// // );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from "react";
import ReactDOM from "react-dom/client"; // Use this for new API
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const APP = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const rootElement = document.getElementById("root");

// Use the new hydrateRoot for hydration in React 18+
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, APP);
} else {
  ReactDOM.createRoot(rootElement).render(APP);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// import React from "react";
// import "./index.css";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";
// import { hydrateRoot } from "react-dom/client";

// const APP = (
//   <BrowserRouter>
//     <App tab="home" />
//   </BrowserRouter>
// );

// const container = document.getElementById("root");

// hydrateRoot(container, APP);
