import React from "react";
import { Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-white py-3">
        <div class="container">
          <Link
            to="/"
            className="navbar-brand d-flex align-items-center px-4 px-lg-5"
          >
            <img
              src="../../../assets/img/1.png"
              style={{ width: "100px" }}
              alt=""
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                About
              </NavLink>
              <NavLink
                to="/courses"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                Courses
              </NavLink>
              <NavLink
                to="/gallery"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                Gallery
              </NavLink>

              <NavLink
                to="/results"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                Results
              </NavLink>
              <NavLink
                to="/allteam"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                Team
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              >
                Contact
              </NavLink>
            </div>
            <Link
            to="/contact"
            className="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
          >
            Join Now<i className="fa fa-arrow-right ms-3"></i>
          </Link>
          </div>
        </div>
      </nav>
     
    </>
  );
}

export default Header;
