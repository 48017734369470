import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Quick Link</h4>
              <Link to="/about" className="btn btn-link">
                About Us
              </Link>
              <Link to="/contact" className="btn btn-link">
                Contact Us
              </Link>
              <Link to="/gallery" className="btn btn-link">
                Gallery
              </Link>
              <Link to="/allteam" className="btn btn-link">
                Team
              </Link>
              <Link to="/results" className="btn btn-link">
                Results
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Contact</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>
                2nd Floor, Khushi Tower MLB COLONY, Padav, Gwalior, Madhya
                Pradesh , India - 474004
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+91-7415486473
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>
                <a style={{textDecoration:"none",color:"#fff"}} href="mailto:reachus@aishacoachingclasses.in">
                  reachus@aishacoachingclasses.in
                </a>
              </p>
              <div className="d-flex pt-2">
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/aishacoachingclasses/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/aishacoachingclasses"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.linkedin.com/company/aishacoachingclasses/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=co.arya.bomzc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ maxWidth: "100px" }}
                    src="../../../assets/img/googleplaystore.png"
                    alt="Play Store"
                  ></img>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Aisha Humanities</h4>
              <p> Discover, Learn, and Grow with Aisha's Humanities.</p>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: "400px" }}
              >
                <img
                  src="../../../assets/img/1.png"
                  style={{ width: "150px" }}
                  alt="Aisha Humanities Logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <Link to="/" className="border-bottom">
                  aishacoachingclasses.in
                </Link>
                , All Rights Reserved.
                <span> Designed By </span>
                <a
                  className="border-bottom"
                  href="https://www.justdial.com/jdmart/Gwalior/Ad-Web-Technology--Gurunanak-Nagar/9999PX751-X751-230301015518-T6R2_BZDET/catalogue?nid=10801033"
                  target="_blank"
                  rel="noreferrer"
                >
                  AD Web Technology
                </a>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/" className="me-3">
                    Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
