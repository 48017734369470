import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Testimonial() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="text-center">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Testimonial
          </h6>
          <h1 className="mb-5">Our Students Say!</h1>
        </div>
        <OwlCarousel
          className="owl-theme testimonial-carousel position-relative"
          loop
          margin={10}
          nav
          items={1} // Number of items to display
          autoplay // Enable autoplay
          autoplayTimeout={3000} // Autoplay interval (in milliseconds)
          autoplayHoverPause // Pause on hover
        >
          <div className="testimonial-item item text-center">
            <img
              className="border rounded-circle p-2 mx-auto mb-3"
              src="/assets/img/testimonial-1.jpg"
              style={{ width: "80px", height: "80px" }}
              alt=""
            />
            <h5 class="mb-0">Gungun Tomar</h5>

            <p>Studant</p>
            <div class="testimonial-text bg-light text-center p-4">
              <p class="mb-0">
                Best institute in gwalior for humanities. All over the
                experience is good.
                <br /> The friendly nature of teacher is very good thing. All
                over the experience is good!!
              </p>
            </div>
          </div>
          <div className="testimonial-item item text-center">
            <img
              className="border rounded-circle p-2 mx-auto mb-3"
              src="/assets/img/testimonial-2.jpg"
              style={{ width: "80px", height: "80px" }}
              alt=""
            />
            <h5 class="mb-0">KUNCHIT SHRIVASTAVA</h5>

            <p>Studant</p>
            <div class="testimonial-text bg-light text-center p-4">
              <p class="mb-0">
                Amazing classes👍 Being a part of Arif sir, I am Enjoying the
                <br />
                studies. Positive Communication, Quality, Professionalism,
                Value.
              </p>
            </div>
          </div>

          <div className="testimonial-item item text-center">
            <img
              className="border rounded-circle p-2 mx-auto mb-3"
              src="/assets/img/testimonial-4.jpg"
              style={{ width: "80px", height: "80px" }}
              alt=""
            />
            <h5 class="mb-0">Harish Katroliya</h5>
            <p>Profession</p>
            <div className="testimonial-text bg-light text-center p-4">
              <p className="mb-0">
                My experience with Aisha art academy is quite well. The teachers
                are very friendly, knowledgeable, understanding. <br />
                Equal attention is given to every student which is the best part
                of this academy.
                <br /> Before joining this academy I found history very boring
                subject but after joining this academy is really interesting
                subject. <br />
                The ncert is explain line by line, provide proper notes, doubt
                class also held.
                <br /> The most important is that the environment of coaching is
                very good.
              </p>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}

export default Testimonial;
