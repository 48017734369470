import React from "react";
import { NavLink } from "react-router-dom";

function Team() {
    
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Instructors
            </h6>
            <h1 className="mb-5">Expert Instructors</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/team-1.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Arif Sir</h5>
                  <small>Director</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/team-2.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Aisha Mam</h5>
                  <small>Foundar</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/team-3.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Deepak Tiwari</h5>
                  <small>Faculty</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/team-4.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Yogendra sharma</h5>
                  <small>Faculty</small>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center wow fadeInUp mt-3" data-wow-delay="0.1s">
            <NavLink to="/allteam">
              <button className="btn btn-primary rounded-1">More</button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );

}

export default Team