import React from "react";

function Syllabus() {
  const syllabusData = {
    title: "Class 12th",
    courses: [
      {
        name: "ECONOMICS SYLLABUS 2024-2025",
        viewLink: "/assets/pdf/ECONOMICS_SYLLABUS_2024-2025.pdf",
        downloadLink: "/assets/pdf/12th_class_economics_syllabus.pdf",
        downloadName: "12th_class_economics_syllabus.pdf",
      },
      {
        name: "ENGLISH SYLLABUS 2024-2025",
        viewLink: "/assets/pdf/ENGLISH SYLLABUS 2024-2025.pdf",
        downloadLink: "/assets/pdf/ENGLISH SYLLABUS 2024-2025.pdf",
        downloadName: "ENGLISH SYLLABUS 2024-2025.pdf",
      },
      {
        name: "GEOGRAPHY  SYLLABUS  2024-2025",
        viewLink: "/assets/pdf/GEOGRAPHY  SYLLABUS  2024-2025.pdf",
        downloadLink: "/assets/pdf/GEOGRAPHY  SYLLABUS  2024-2025.pdf",
        downloadName: "GEOGRAPHY  SYLLABUS  2024-2025.pdf",
      },
      {
        name: "HISTORY SYLLABUS 2024-2025",
        viewLink: "/assets/pdf/HISTORY SYLLABUS 2024-2025.pdf",
        downloadLink: "/assets/pdf/HISTORY SYLLABUS 2024-2025.pdf",
        downloadName: "HISTORY SYLLABUS 2024-2025.pdf",
      },
      {
        name: "LEGAL STUDIES SYLLABUS 2024-2025",
        viewLink: "/assets/pdf/LEGAL STUDIES SYLLABUS 2024-2025.pdf",
        downloadLink: "/assets/pdf/LEGAL STUDIES SYLLABUS 2024-2025.pdf",
        downloadName: "LEGAL STUDIES SYLLABUS 2024-2025.pdf",
      },
      {
        name: "POLITICAL  SCIENCE  SYLLABUS 2024-2025",
        viewLink: "/assets/pdf/POLITICAL  SCIENCE  SYLLABUS 2024-2025.pdf",
        downloadLink: "/assets/pdf/POLITICAL  SCIENCE  SYLLABUS 2024-2025.pdf",
        downloadName: "POLITICAL  SCIENCE  SYLLABUS 2024-2025.pdf",
      },
      {
        name: "SOCIOLOGY SYLLABUS 2024-2025",
        viewLink: "/assets/pdf/SOCIOLOGY SYLLABUS 2024-2025.pdf",
        downloadLink: "/assets/pdf/SOCIOLOGY SYLLABUS 2024-2025.pdf",
        downloadName: "SOCIOLOGY SYLLABUS 2024-2025.pdf",
      },
  
      // Add more courses as needed
    ],
  };

  return (
    <>
     
      <div className="container-xxl py-5 category">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Syllabus
            </h6>
            <h1 className="mb-5">{syllabusData.title}</h1>
          </div>
          <div className="row g-3">
            <table className="table">
              <thead>
                <tr>
                  <th>Course Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {syllabusData.courses.map((course, index) => (
                  <tr key={index}>
                    <td>{course.name}</td>
                    <td>
                      <a
                        style={{ backgroundColor: "#000", marginRight: "10px" }}
                        href={course.viewLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                      >
                        View
                      </a>
                      <a
                        style={{ backgroundColor: "#000" }}
                        href={course.downloadLink}
                        download={course.downloadName}
                        className="btn btn-primary"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Syllabus;
