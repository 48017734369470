import React from "react";
import { useNavigate } from "react-router-dom";

function CourseContent() {
  const navigate = useNavigate();

  const categories = [
    { name: "History", image: "../../../assets/img/1.jpeg", link: "#" },
    {
      name: "Mathematics",
      image: "../../../assets/img/2.jpeg",
      link: "#",
    },
    { name: "Science", image: "../../../assets/img/3.jpeg", link: "#" },
    {
      name: "Literature",
      image: "../../../assets/img/4.jpeg",
      link: "#",
    },
    { name: "Literature", image: "../../../assets/img/5.png", link: "#" },
    { name: "Literature", image: "../../../assets/img/6.jpg", link: "#" },
    { name: "Literature", image: "../../../assets/img/7.jpg", link: "#" },
    // Add more categories as needed
  ];

  const handleNavigation = (link) => {
    navigate(link);
  };

  return (
    <>
      <div className="container-xxl py-5 category">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Categories
            </h6>
            <h1 className="mb-5">Courses</h1>
          </div>
          <div className="row g-3">
            {categories.map((category, index) => (
              <div
                key={index}
                className="col-lg-3 col-md-4 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <div
                  className="position-relative d-block overflow-hidden"
                  onClick={() => handleNavigation(category.link)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="img-fluid"
                    src={category.image}
                    alt={category.name}
                  />
                  <div
                    className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                    style={{ margin: "1px" }}
                  >
                    <h5 className="m-0">{category.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseContent;
