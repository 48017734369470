import React, { useEffect } from "react";

function AboutContent() {
     useEffect(() => {
       window.scrollTo(0, 0); // Scroll to the top of the page
     }, []);

  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative ">
                <img
                  className="img-fluid position-absolute "
                  src="/assets/img/1.png"
                  alt=""
                  style={{ objectFit: "cover", width: "70%" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 className="mb-4">Welcome to AISHA Coaching Classes</h1>
              <p className="mb-4">
                At Aisha Coaching Classes, we sculpt minds with passion and
                precision, fostering academic excellence in every student.
              </p>
              <p className="mb-4">
                Welcome to Aisha Humanities Academy, where the art of learning
                meets the science of success, shaping tomorrow's leaders.
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Skilled Instructors
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Online Classes
                  </p>
                </div>

                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Home Projects
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Notes and Study Material
                  </p>
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutContent