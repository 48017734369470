import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactContent from "./content/ContactContent";
import ContactBreadCrumb from "./breadcrumbs/ContactBreadCrumb";

function Contact() {
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    
  return (
    <>
      <Helmet>
        <title>Contact Us - Aisha Coaching Classes</title>
        <meta
          name="description"
          content="Get in touch with Aisha Coaching Classes for inquiries, feedback, or support. We are here to help you."
        />
        <meta
          name="keywords"
          content="contact us, support, inquiries, Aisha Coaching Classes"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <ContactBreadCrumb />
      <ContactContent />
    </>
  );
}

export default Contact;
