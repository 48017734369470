import React, { useEffect , useState } from "react";
import Masonry from "react-responsive-masonry";
import { Helmet } from "react-helmet";
import Modal from "react-modal";

// Ensure that Modal's default styles are applied
Modal.setAppElement("#root");

const images = [
  "/assets/gallery/1714769076776.jpg",
  "/assets/gallery/IMG_1999.jpg",
  "/assets/gallery/IMG_2007.jpg",
  "/assets/gallery/IMG_2005.jpg",
  "/assets/gallery/IMG_2015.jpg",
  "/assets/gallery/IMG_6183.jpg",
  "/assets/gallery/IMG_6195.jpg",
  "/assets/gallery/IMG_6201.jpg",
  "/assets/gallery/IMG_6212.jpg",
];

function ImageGallery() {
      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Helmet>
        <title>Gallery - Aisha Coaching Classes </title>
        <meta
          name="description"
          content="Browse through the gallery of Aisha Coaching Classes to see our events, workshops, and classroom activities."
        />
        <meta
          name="keywords"
          content="gallery, events, workshops, Aisha Coaching Classes"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="container-fluid bg-primary py-5 mb-5 page-header">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Gallery
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/gallery">
                      Gallery
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Masonry columnsCount={5} gutter="10px">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            style={{ width: "100%", display: "block", cursor: "pointer" }}
            alt={`Gallery item ${i + 1}`}
            onClick={() => openModal(image)}
          />
        ))}
      </Masonry>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0",
            border: "none",
            background: "none",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            color: "#fff",
            fontSize: "24px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        {selectedImage && (
          <img
            src={selectedImage}
            style={{ width: "100%", height: "100vh" }}
            alt="Full screen view"
          />
        )}
      </Modal>
    </>
  );
}

export default ImageGallery;
