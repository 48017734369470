import React from 'react'
import { Helmet } from 'react-helmet';
import Team from './Team';
import AboutBreadCrumb from './breadcrumbs/AboutBreadCrumb';
import AboutContent from './content/AboutContent';

function About() {
  return (
    <>
      <Helmet>
        <title>About Us - Aisha Coaching Classes</title>
        <meta
          name="description"
          content="Learn more about Aisha Coaching Classes, our mission, vision, and the team behind our success."
        />
        <meta
          name="keywords"
          content="about us, Aisha Coaching, mission, vision, coaching team"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <AboutBreadCrumb />
      <AboutContent />
      <Team />
    </>
  );
}

export default About