import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CourseBreadCrumb from "./breadcrumbs/CourseBreadCrumb";
import CourseContent from "./content/CourseContent";
import Syllabus from "./content/SyllabusTable";

function Courses() {
   useEffect(() => {
     window.scrollTo(0, 0); // Scroll to the top of the page
   }, []);


  return (
    <>
      <Helmet>
        <title>Courses - Aisha Coaching Classes</title>
        <meta
          name="description"
          content="Explore the range of courses offered by Aisha Coaching Classes, designed to help you excel in your studies."
        />
        <meta
          name="keywords"
          content="courses, academic coaching, education, Aisha Coaching Classes"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <CourseBreadCrumb />
      <CourseContent />
      <Syllabus />
    </>
  );
}

export default Courses;
