import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

function HeaderCarousel() {
  return (
    <div className="container-fluid p-0 mb-5">
      <OwlCarousel
        className="owl-theme header-carousel position-relative"
        loop
        margin={0}
        nav={false}
        dots={false}
        items={1} // Number of items to display
        autoplay // Enable autoplay
        autoplayTimeout={3000} // Autoplay interval (in milliseconds)
        autoplayHoverPause // Pause on hover
      >
        <div className="owl-carousel-item position-relative">
          <img
            className="desk img-fluid vh-100"
            style={{ objectFit: "cover" }}
            src="../../../assets/img/carousel-1.jpg"
            alt=""
          />
          <img
            className="mob img-fluid vh-100"
            style={{ objectFit: "cover" }}
            src="../../../assets/img/carousel-1 - Copy.jpg"
            alt=""
          />

          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(24, 29, 56, .7)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-sm-10 col-lg-8">
                  <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                    Our Toopers
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown">
                    Get Educated From Aisha Humanities.{" "}
                  </h1>
                  <p className="fs-5 text-white mb-4 pb-2">
                    Empower yourself with a deeper appreciation for humanities
                    through Aisha's expert guidance.
                  </p>

                  <button className="btn btn-primary  py-md-3 px-md-5 animated slideInRight">
                    <Link to="/contact">Join Now</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-carousel-item position-relative">
          <img
            className="desk img-fluid vh-100"
            style={{ objectFit: "cover" }}
            src="../../../assets/img/carousel-2.jpg"
            alt=""
          />
          <img
            className="mob img-fluid vh-100"
            style={{ objectFit: "cover" }}
            src="../../../assets/img/Banner.png"
            alt=""
          />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(24, 29, 56, .7)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-sm-10 col-lg-8">
                  <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                    Best Arts and Humanities Classes
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown">
                    Discover, Learn, and Grow with Aisha Humanities
                  </h1>
                  <p className="fs-5 text-white mb-4 pb-2">
                    Empower yourself with a deeper appreciation for humanities
                    through Aisha's expert guidance.
                  </p>

                  <button className="btn btn-primary  py-md-3 px-md-5 animated slideInRight">
                    <Link to="/contact">Join Now</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
}

export default HeaderCarousel;
