import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Results() {
   useEffect(() => {
     window.scrollTo(0, 0); // Scroll to the top of the page
   }, []);
  return (
    <>
      <Helmet>
        <title>Results - Aisha Coaching Classes </title>
        <meta
          name="description"
          content="View the results of various exams and assessments conducted by Aisha Coaching Classes. Stay updated with your performance."
        />
        <meta
          name="keywords"
          content="results, exam results, 12th mp board and cbse results, Aisha Coaching Classes"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="">Toppers</h1>

            <h6 className="section-title bg-white text-center text-primary px-3">
              Our Pride
            </h6>
            <h5 className="p-4">Batch - 2022-23</h5>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_003255 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4 table-responsive">
                  <h5 className="mb-0">Vishakha Pamnani</h5>
                  <button
                    type="button"
                    class="btn btn-primary mt-3 mb-3 position-relative"
                  >
                    Ex-Studant
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      Rank-1
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </button>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics </td>
                      <td>
                        <span className="badge bg-warning text-dark">
                          100/100
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>History  </td>
                      <td>95/100</td>
                    </tr>

                    <tr className="border-bottom mt-3">
                      <td>Political Science </td>
                      <td>95/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_003014 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Anirudhra Yadav</h5>
                  <small>
                    <button
                      type="button"
                      class="btn btn-primary mt-3 mb-3 position-relative"
                    >
                      Ex-Studant
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        Rank-2
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </small>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics  </td>
                      <td>
                        <span className="badge bg-warning text-dark">
                          95/100
                        </span>
                      </td>
                    </tr>

                    <tr className="border-bottom mt-3">
                      <td>History</td>
                      <td>89/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Political science</td>
                      <td>95/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_003156 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Kunchit shrivastava </h5>
                  <small>
                    <button
                      type="button"
                      class="btn btn-primary mt-3 mb-3 position-relative"
                    >
                      Ex-Studant
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        Rank-3
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </small>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics  </td>
                      <td>
                        <span className="badge bg-warning text-dark">
                          91/100
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>History</td>
                      <td>93/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Political science</td>
                      <td>88/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <h5 className="p-4 text-center">Batch - 2023-24</h5>

          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_011923 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4 table-responsive">
                  <h5 className="mb-0">Mansi Sharma </h5>
                  <button
                    type="button"
                    class="btn btn-primary mt-3 mb-3 position-relative"
                  >
                    Ex-Studant
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      Rank-1
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </button>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics </td>
                      <td>
                        <span className="badge bg-warning text-dark">
                          90/100
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>History  </td>
                      <td>87/100</td>
                    </tr>

                    <tr className="border-bottom mt-3">
                      <td>Political Science </td>
                      <td>92/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics </td>
                      <td>90/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_011847 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Ujjawal Lodhi</h5>
                  <small>
                    <button
                      type="button"
                      class="btn btn-primary mt-3 mb-3 position-relative"
                    >
                      Ex-Studant
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        Rank-2
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </small>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>

                    <tr className="border-bottom mt-3">
                      <td>History</td>
                      <td>91/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>English </td>
                      <td>89/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_011600 (1) (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Yashasvi Rajput</h5>
                  <small>
                    <button
                      type="button"
                      class="btn btn-primary mt-3 mb-3 position-relative"
                    >
                      Ex-Studant
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        Rank-3
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </small>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>History  </td>
                      <td>
                        <span className="badge bg-warning text-dark">
                          86/100
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Sociology </td>
                      <td>87/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics </td>
                      <td>78/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>English </td>
                      <td>86/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_011637 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Raghuraj singh yadav</h5>
                  <small>
                    <button
                      type="button"
                      class="btn btn-primary mt-3 mb-3 position-relative"
                    >
                      Ex-Studant
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        Rank-2
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </small>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics  </td>
                      <td>
                        <span className="badge bg-warning text-dark">
                          71/100
                        </span>
                      </td>
                    </tr>

                    <tr className="border-bottom mt-3">
                      <td>History</td>
                      <td>86/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Political science</td>
                      <td>79/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="../../../assets/img/Photoroom-20240726_011755 (1).png"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">Raghuveer Dhakad </h5>
                  <small>
                    <button
                      type="button"
                      class="btn btn-primary mt-3 mb-3 position-relative"
                    >
                      Ex-Studant
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        Rank-3
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </small>
                  <table className="table border-bottom mt-3">
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                    </tr>

                    <tr className="border-bottom mt-3">
                      <td>History</td>
                      <td>91/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>English</td>
                      <td>88/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td className="">English</td>
                      <td>80/100</td>
                    </tr>
                    <tr className="border-bottom mt-3">
                      <td>Economics</td>
                      <td>79/100</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Results;
