import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Testimonial from './Testimonial';
import HeaderCarousel from './HeaderCarousel';
import Team from './Team';
import CourseContent from './content/CourseContent';

function Home() {
   useEffect(() => {
     window.scrollTo(0, 0); // Scroll to the top of the page
   }, []);

  return (
    <>
      <Helmet>
        <title>Home - Aisha Coaching Classes </title>
        <meta
          name="description"
          content="Welcome to Aisha Coaching Classes and Aisha Humanities. Discover our arts and humanities classes to help you achieve your goals."
        />
        <meta
          name="keywords"
          content="coaching classes, humanities classes in gwalior, arts classes, humanities classes, Aisha Coaching"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <HeaderCarousel />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
                  <h5 className="mb-3">Skilled Instructors</h5>
                  <p>
                    Skilled instructors are knowledgeable, engaging, patient,
                    and effective at communicating concepts, inspiring students,
                    and creating a positive learning atmosphere.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Online Classes</h5>
                  <p>
                    Online classes offer flexibility, accessibility, and a
                    variety of learning resources, enabling students to study
                    anytime, anywhere, at their own pace.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-home text-primary mb-4"></i>
                  <h5 className="mb-3">Home Projects</h5>
                  <p>
                    Home projects foster creativity, skill-building, and
                    personal satisfaction, providing a productive way to enhance
                    living spaces and learn new skills.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-book-open text-primary mb-4"></i>
                  <h5 className="mb-3">Notes and Study Material</h5>
                  <p>
                    Notes and study material help organize information,
                    reinforce learning, and serve as valuable resources for exam
                    preparation and knowledge retention.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CourseContent />
      <Team />
      <Testimonial />
    </>
  );
};

export default Home;
